@charset "utf-8";

html {
  width: 100%;
}
body {
  width: 100%;
	margin: 0;
	padding: 0;
	font-family: $font-notoserif;
	font-style: normal;
	font-weight: 300;
	color: $black;
	-webkit-text-size-adjust: none;
	/* -webkit-font-smoothing: antialiased; */
	font-size: $base-fs;
  line-height: $base-lh;
  overflow-x: hidden;
  @include mq(pc) {
    min-width: 1064px;
  }
  @include mq(tab) {
    width: 1064px;
  }
}

a {
	color: $black;
  text-decoration: none;
  transition: .2s;
  &:hover {
    transition: .2s;
  }
  &:visited {
  }
}

img {
  max-width: 100%;
  height: auto;
}

a[href]:hover img {
  @include mq(pc) {
    opacity: 0.9;
  }
}