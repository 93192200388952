@charset "utf-8";

/*--------------------------------------------------------
  タイトル
----------------------------------------------------------*/
/* 見出し */
.t-top-01 {
  margin-bottom: 30px;
  padding: 10px 15px 16px 60px;
  border-bottom: 3px solid $sub;
  background: url(../img/common/icn_logo.svg) left 14px no-repeat;
  background-size: 37px 37px;
  color: $sub;
  font-size: 27px;
  font-weight: 400;
  @include mq(sp) {
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 6px 0 8px 45px;
    background-position: left 8px;
    background-size: 28px 28px;
    font-size: 20px;
  }
  a {
    color: #000;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.t-top-02 {
  margin-bottom: 40px;
  padding: 0;
  border: 0;
  background: none;
  font-size: 32px;
  font-weight: 500;
  @include mq(sp) {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 1.2;
  }
}

.t-top-03 {
  margin-bottom: 40px;
  padding: 0;
  border: 0;
  background: none;
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  @include mq(sp) {
    font-size: 24px;
    line-height: 1.2;
  }
}

.t-top-04 {
  margin-bottom: 40px;
  padding: 0;
  padding-bottom: 30px;
  border: 0;
  background: transparent url(../img/common/icn_logo.svg) center bottom no-repeat;
  background-size: 30px 30px;
  color: $sub;
  font-family: $font-vollkorn;
  font-size: 35px;
  font-weight: 400;
  text-align: center;
  @include mq(sp) {
    margin-bottom: 20px;
    padding-bottom: 24px;
    background-size: 22px 22px;
    font-size: 24px;
    line-height: 1.2;
  }
}

.t-top-05 {
  margin-bottom: 40px;
  padding: 10px 30px;
  background: $gra2;
  font-size: 21px;
  font-weight: 400;
  @include mq(sp) {
    margin-bottom: 20px;
    padding: 7px 20px;
    font-size: 17px;
  }
}

.contents--page {
  h1 {
    margin-bottom: 50px;
    font-size: 36px;
    font-weight: 400;
    text-align: center;
    @include mq(sp) {
      margin-bottom: 30px;
      padding-top: 30px;
      font-size: 30px;
    }
    a {
      color: $sub;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.title-header {
  position: relative;
  @include mq(sp) {
    margin-bottom: 30px;
  }
  .more-link {
    position: absolute;
    top: 12px;
    right: 0;
    @include mq(sp) {
      position: static;
      text-align: right;
    }
    a {
      display: inline-block;
      padding: 8px 40px;
      background: $gra;
      color: $black;
      font-size: 14px;
      text-decoration: none;
      @include mq(sp) {
        padding: 7px 30px;
        font-size: 13px;
      }
    }
  }
}

.notice {
  color: #777;
  font-size: 13px;
}