
/*--------------------------------------------------------
  ぱんくず
----------------------------------------------------------*/

.breadcrumbs {
  width: 1020px;
  margin: 20px auto 30px;
  padding: 0 10px;
  font-size: 13px;
  box-sizing: border-box;
  @include mq(sp) {
    display: none;
  }
}
