/*--------------------------------------------------------
	ボタン
----------------------------------------------------------*/

.btn-def {
    @include mq(sp) {
        text-align: center;
    }
    a {
        position: relative;
        display: inline-block;
        width: 190px;
        padding: 14px 0 15px;
        background: $sub;
        color: #fff;
        font-size: 14px;
        text-align: center;
        text-decoration: none;
        -webkit-font-smoothing: antialiased;
        &::before {
            position: absolute;
            left: 0;
            top: 50%;
            content: "";
            display: block;
            width: 28px;
            height: 1px;
            background: #fff;
        }
        &:hover {
            opacity: 0.8;
        }
    }
}

.btn-def-big {
    text-align: center;
    a {
        position: relative;
        display: inline-block;
        width: 400px;
        padding: 14px 0 15px;
        background: $sub;
        color: #fff;
        font-size: 24px;
        text-align: center;
        text-decoration: none;
        -webkit-font-smoothing: antialiased;
        @include mq(sp) {
            width: 100%;
            font-size: 18px;
        }
        &:hover {
            opacity: 0.8;
        }
    }
}

.btn-gra {
    text-align: center;
    a {
        position: relative;
        display: inline-block;
        width: 280px;
        padding: 16px 0;
        background: rgb(245,192,202);
        background: linear-gradient(90deg, rgba(245,192,202,1) 0%, rgba(219,223,238,1) 100%);
        font-size: 20px;
        text-decoration: none;
        @include mq(sp) {
            font-size: 15px;
        }
        &::before {
            position: absolute;
            left: 0;
            top: 50%;
            content: "";
            display: block;
            width: 36px;
            height: 1px;
            background: $black;
        }
        &:hover {
            opacity: 0.8;
        }
    }
}

.btn-white {
    @include mq(sp) {
        text-align: center;
    }
    a {
        position: relative;
        display: inline-block;
        width: 280px;
        padding: 18px 0 20px;
        background: #fff;
        font-size: 20px;
        text-align: center;
        text-decoration: none;
        @include mq(sp) {
            font-size: 15px;
        }
        &::before {
            position: absolute;
            left: 0;
            top: 50%;
            content: "";
            display: block;
            width: 36px;
            height: 1px;
            background: $black;
        }
        &:hover {
            opacity: 0.8;
        }
    }
}

.btn-ppl {
    a {
        display: inline-block;
        width: 280px;
        padding: 10px 0 12px;
        background: $main;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        text-align: center;
        text-decoration: none;
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        @include mq(sp) {
            font-size: 15px;
        }
        &:hover {
            background: $light-main;
            color: $black;
        }
    }
}

.btn-ig {
    a {
        display: inline-block;
        width: 280px;
        padding: 10px 0 12px;
        border-radius: 8px;
        background: #fff url(../img/common/icn_ig.svg) 50px center no-repeat;
        background-size: 23px 23px;
        color: $black;
        font-size: 17px;
        font-weight: 500;
        text-align: center;
        text-decoration: none;
        -webkit-font-smoothing: antialiased;
        @include mq(sp) {
            font-size: 15px;
        }
        &:hover {
        }
    }
}