@charset "utf-8";

/*--------------------------------------------------------
  メインイメージ
----------------------------------------------------------*/

/* mainimage */
.mainimage {
  @include base-wrap--space;
  &__wrap {
    height: 450px;
    overflow: hidden;
    @include mq(sp) {
      height: auto;
    }
  }
}

@include mq(sp) {
  .bx-prev, .bx-next {
    display: none;
  }
  .bx-wrapper {
    margin-bottom: 20px;
  }
}