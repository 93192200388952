@charset "utf-8";

/*--------------------------------------------------------
  ヘッダー
----------------------------------------------------------*/

/* ヘッダー */
.header {
  padding: 20px;
  @include mq(sp) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    padding: 12px 0 10px;
    background: #fff;
    z-index: 99;
  }
  &__logo {
    text-align: center;
    @include mq(sp) {
      height: 40px;
    }
    img {
      width: 280px;
      @include mq(sp) {
        width: 200px;
        height: 40px;
      }
    }
  }
  &__text {
    margin: 14px 0 0;
    font-size: 13px;
    letter-spacing: .13em;
    text-align: center;
    @include mq(sp) {
      display: none;
      position: absolute;
      top: 10px;
      left: 10px;
      margin: 0;
      text-align: left;
    }
  }
}

/* ナビゲーション */

.nav-global {
  padding-bottom: 5px;
  @include mq(sp) {
    position: absolute;
    left: 0;
    top: 62px;
    display: none;
    width: 100%;
    padding-bottom: 0;
    z-index: 20;
  }
  &--top {
    padding-bottom: 35px;
    @include mq(sp){
      padding-bottom: 0;
    }
  }
  &__inner {
    @include base(1100,0,0);
    width: 1100px;
  }
  &__sub {
    display: none;
    @include mq(sp) {
      display: block;
      padding: 20px 0 35px;
      background: $sub;
      text-align: center;
    }
    .btn-white {
      margin-bottom: 15px;
    }
  }
  ul {
    @include flex();
    li {
      flex-grow: 1;
      position: relative;
      font-size: 16px;
      line-height: 1;
      text-align: center;
      @include mq(sp) {
        border-bottom: 1px solid #fff;
        font-size: 14px;
        text-align: left;
      }
      span {
        color: $gray;
        font-size: 10px;
        font-family: $font-vollkorn;
        @include mq(sp) {
          display: none;
        }
      }
      & > a {
        position: relative;
        display: block;
        padding: 10px 0;
        text-decoration: none;
        box-sizing: border-box;
        @include mq(sp) {
          display: block;
          width: 100%;
          padding: 16px 0 16px 46px;
          background: $sub;
          color: #fff;
          text-decoration: none;
        }
        &::after {
          @include mq(pc) {
            position: absolute;
            top: 50%;
            right: -1px;
            content: "";
            display: block;
            width: 1px;
            height: 50%;
            border-right: 1px dotted $black;
            transform: translateY(-50%);
          }
          @include mq(sp) {
            position: absolute;
            top: 50%;
            left: 20px;
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            border-right: 1px solid #fff;
            border-top: 1px solid #fff;
            transform: rotate(45deg) translateY(-50%);
          }
        }
        &:hover {
          @include mq(pc) {
            background: $light-main;
          }
        }
      }
      &:last-child {
        @include mq(sp) {
          display: none;
        }
        a {
          &::after {
            @include mq(pc) {
              display: none;
            }
          }
        }
      }
      dl {
        position: absolute;
        left: -20%;
        top: 52px;
        width: 150%;
        background: $sub;
        text-align: left;
        z-index: 10;
        transition: .5s;
        @include mq(pc) {
          opacity: 0;
          visibility: hidden;
        }
        @include mq(sp) {
          position: static;
          width: 100%;
        }
        dt {
          border-top: 1px solid #fff;
          @include mq(sp) {
          }
          span {
            display: block;
            padding: 16px 0 16px 20px;
            color: #fff;
            font-size: 13px;
            @include mq(sp) {
              padding: 16px 0 16px 60px;
            }
          }
          a {
            position: relative;
            padding: 16px 0 16px 20px;
            @include mq(sp) {
              padding: 16px 0 16px 60px;
            }
            &::after {
              @include mq(sp) {
                position: absolute;
                top: 50%;
                left: 36px;
                content: "";
                display: block;
                width: 8px;
                height: 8px;
                border-right: 1px solid #fff;
                border-top: 1px solid #fff;
                transform: rotate(45deg) translateY(-50%);
              }
            }
          }
        }
        dd {
          @include mq(sp) {
            border-bottom: 1px solid $sub;
          }
          &.last-child {
            @include mq(sp) {
              border-bottom: 0;
            }
          }
          a {
            padding: 0 10px 16px;
            @include mq(sp) {
              padding: 16px 0 16px 50px;
              background: #fff;
              color: $sub;
            }
            &::before {
              content: "・";
            }
          }
        }
        a {
          display: block;
          color: #fff;
          font-size: 12px;
          -webkit-text-size-adjust: none;
          @include mq(sp) {
            font-size: 14px;
          }
          &:hover {
            color: $light-main;
          }
        }
      }
      &:hover {
        dl {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
