@charset "utf-8";

$black: #595757;
$gray: #B0B0B0;/* グレー */
$light-gray: #E3E7ED;/* 薄いグレー */
$main: #cf9dbf;/* 紫 */
$sub: #a77d39;/* 金 */
$light-main: #E9E0EB;/* 薄紫 */
$gra: linear-gradient(90deg, rgba(245,192,202,1) 0%, rgba(219,223,238,1) 100%);
$gra2: linear-gradient(-90deg, rgba(245,192,202,1) 0%, rgba(219,223,238,1) 100%);

$base-lh: 1.5;
$base-fs: 15px;

@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@300;400;500&family=Vollkorn&display=swap');

$font-notoserif: 'Noto Serif JP', serif;
$font-vollkorn: 'Vollkorn', serif;

$marugo: "ヒラギノ丸ゴ Pro W4","Hiragino Maru Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$mincho: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro","ＭＳ Ｐ明朝", "MS PMincho", serif;
$font-yu: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
$mincho-yu: "游明朝" , "Yu Mincho" , "游明朝体" , "YuMincho" , "ヒラギノ明朝 Pro W3" , "Hiragino Mincho Pro" , "HiraMinProN-W3" , "ＭＳ Ｐ明朝" , "MS PMincho" , serif;
$font-times: 'Times New Roman','Luchida Grande';
$font-round: "M PLUS Rounded 1c";
