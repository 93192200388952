@charset "utf-8";

/*--------------------------------------------------------
  フッター
----------------------------------------------------------*/
.footer {
    margin-top: 30px;
    margin-bottom: -20px;
    padding-top: 30px;
    background: url(../img/common/bg_top.png) top no-repeat, $gra;
    &__inner {
        @include base-wrap--space();
        padding: 40px 0;
        @include mq(sp) {
            width: auto;
            margin: 10px auto;
            padding: 40px 0;
        }
    }
    &__logo {
        float: left;
        margin-right: 65px;
        margin-top: 24px;
        @include mq(sp) {
            float: none;
            margin: 0;
            text-align: center;
        }
        img {
            width: 150px;
            @include mq(sp) {
                width: 120px;
            }
        }
    }
    &__address {
        float: left;
        margin-top: 30px;
        @include mq(sp) {
            float: none;
            margin-bottom: 40px;
            text-align: center;
        }
    }
    &__contact {
        float: right;
        margin-top:20px;
        @include mq(sp) {
            float: none;
            text-align: center;
        }
        a {
            margin-bottom: 14px;
            @include mq(sp) {
                margin-bottom: 25px;
            }
        }
    }
}

.footer {
    .copyright {
        margin-top: 20px;
        font-size: 13px;
        text-align: center;
        -webkit-font-smoothing: antialiased;
        span {
            padding: 4px 8px;
            background: rgba(255,255,255,0.3);
            a {
                text-decoration: none;
            }
        }
    }
}

/* ページ上へ戻る */
#TopLink {
    position: fixed;
    bottom: 30px;
    left: 50%;
    margin-left: 370px;
    z-index: 3;
    @include mq(sp) {
        left: auto;
        right: 10px;
        margin-left: 0;
    }
    a {
        display: block;
        padding: 4px 30px;
        border: 1px solid $main;
        background: #fff;
        text-decoration: none;
        @include mq(sp) {
            padding: 3px 20px 4px;
            font-size: 13px;
        }
    }
}
