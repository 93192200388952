@charset "utf-8";

.contents {
  @include mq(sp) {
  }
  &--page {
    @include clearfix();
    @include base-wrap--space();
    @include mq(sp) {
      padding-top: 62px;
    }
  }
}

#Main1 {
    float: left;
    width: 720px;
}
#Side1 {
    float: right;
    width: 260px;;
}
#Main2 {
    float: right;
    width: 720px;
}
#Side2 {
    float: left;
    width: 260px;;
}

@include mq(sp) {
  #Main1 {
      float: none;
      width: auto;
  }
  #Side1 {
      float: none;
      width: auto;
  }
  #Main2 {
      float: none;
      width: auto;
  }
  #Side2 {
      float: none;
      width: auto;
  }
  .salon-banner {
      width: auto;
      margin-left: 10px;
      margin-right: 10px;
  }
}

.base-wrap {
  width: 1024px;
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
  &--space {
    width: 1024px;
    margin-left: auto;
    margin-right: auto;
    @include mq(sp) {
      width: auto;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.main-wrap {
  width: 920px;
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
  &--space {
    width: 920px;
    margin-left: auto;
    margin-right: auto;
    @include mq(sp) {
      width: auto;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.screen-reader-text {
  display: none;
}
