@charset "utf-8";


/*--------------------------------------------------------
	リスト
----------------------------------------------------------*/

.contents--page .main {
  ul {
    margin: 10px 0 30px 1em;
    & > li {
      position: relative;
      margin-bottom: 10px;
      padding: 0 0 0 16px;
      &::before {
        position: absolute;
        top: 6px;
        left: 0;
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $sub;
      }
      ul {
        li {
          padding: 0;
          &::before {
            display: none;
          }
        }
      }
    }
  }
  ol {
    margin: 10px 0 30px 1em;
    list-style: none;
    & > li {
      position: relative;
      margin-bottom: 10px;
      padding: 0 0 0 22px;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        color: $sub;
      }
      &:nth-child(1)::before {
        content: "1. ";
      }
      &:nth-child(2)::before {
        content: "2. ";
      }
      &:nth-child(3)::before {
        content: "3. ";
      }
      &:nth-child(4)::before {
        content: "4. ";
      }
      &:nth-child(5)::before {
        content: "5. ";
      }
      &:nth-child(6)::before {
        content: "6. ";
      }
      &:nth-child(7)::before {
        content: "7. ";
      }
      &:nth-child(8)::before {
        content: "8. ";
      }
      &:nth-child(9)::before {
        content: "9. ";
      }
      & > ol {
        margin-left: 0;
        & > li {
          position: relative;
          margin-bottom: 10px;
          padding: 0 0 0 26px;
          &::before {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            color: $sub;
          }
          &:nth-child(1)::before {
            content: "(1)";
          }
          &:nth-child(2)::before {
            content: "(2)";
          }
          &:nth-child(3)::before {
            content: "(3)";
          }
          &:nth-child(4)::before {
            content: "(4)";
          }
          &:nth-child(5)::before {
            content: "(5)";
          }
          &:nth-child(6)::before {
            content: "(6)";
          }
          &:nth-child(7)::before {
            content: "(7)";
          }
          &:nth-child(8)::before {
            content: "(8)";
          }
          &:nth-child(9)::before {
            content: "(9)";
          }
          & > ol {
            & > li {
              position: relative;
              margin-bottom: 10px;
              padding: 0 0 0 22px;
              &::before {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                color: $sub;
              }
              &:nth-child(1)::before {
                content: "①";
              }
              &:nth-child(2)::before {
                content: "②";
              }
              &:nth-child(3)::before {
                content: "③";
              }
              &:nth-child(4)::before {
                content: "④";
              }
              &:nth-child(5)::before {
                content: "⑤";
              }
              &:nth-child(6)::before {
                content: "⑥";
              }
              &:nth-child(7)::before {
                content: "⑦";
              }
              &:nth-child(8)::before {
                content: "⑧";
              }
              &:nth-child(9)::before {
                content: "⑨";
              }
            }
          }
        }
      }
    }
  }
}
